import {
  ContainerGroupPriority,
  CreateContainerGroupNetworkingLoadBalancerEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { CreateContainerGroupField } from './CreateContainerGroupField'
import { CreateContainerGroupValues } from './CreateContainerGroupValues'
import { ImageType } from './ImageType'
import { ProtocolOptions } from './ProtocolOptions'

/** The default values for the Ubuntu preset container group. */
export const UbuntuPresetValues: Partial<CreateContainerGroupValues> = {
  [CreateContainerGroupField.IMAGE_SOURCE]: 'ghcr.io/saladtechnologies/recipe-base-ubuntu:0.1',
  [CreateContainerGroupField.READINESS_PROBE_COMMAND]: 'cat',
  [CreateContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS]: [{ argument: '/healthy' }],
  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: true,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: 3,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: [],
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: 3,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: 1,
  [CreateContainerGroupField.READINESS_PROBE_PORT]: 1,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: ProtocolOptions.EXEC,
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: 1,
  [CreateContainerGroupField.GPU]: ['f51baccc-dc95-40fb-a5d1-6d0ee0db31d2'],
}

/** The default values for the SDXL with Refiner preset container group. */
export const SDXLPresetValues: Partial<CreateContainerGroupValues> = {
  [CreateContainerGroupField.NAME]: 'sdxl-with-refiner-comfyui',
  [CreateContainerGroupField.IMAGE_SOURCE]: 'saladtechnologies/comfyui:comfy0.2.0-api1.4.1-sdxl-with-refiner',
  [CreateContainerGroupField.AUTOSTART_POLICY]: true,
  [CreateContainerGroupField.REPLICA_COUNT]: 3,
  [CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED]: true,
  [CreateContainerGroupField.CONTAINER_GATEWAY_PORT]: 3000,
  [CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]: false,
  [CreateContainerGroupField.CONTAINER_GATEWAY_LOAD_BALANCER]:
    CreateContainerGroupNetworkingLoadBalancerEnum.LeastNumberOfConnections,
  [CreateContainerGroupField.CONTAINER_GATEWAY_LIMIT_SERVER]: false,
  [CreateContainerGroupField.CONTAINER_GATEWAY_CLIENT_REQUEST_TIMEOUT]: 100000,
  [CreateContainerGroupField.CONTAINER_GATEWAY_SERVER_RESPONSE_TIMEOUT]: 100000,
  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: true,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.READINESS_PROBE_PATH]: '/ready',
  [CreateContainerGroupField.READINESS_PROBE_PORT]: 3000,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: [],
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: 0,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: 2,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: 2,
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: 3,
  [CreateContainerGroupField.STARTUP_PROBE_ENABLED]: true,
  [CreateContainerGroupField.STARTUP_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.STARTUP_PROBE_PATH]: '/health',
  [CreateContainerGroupField.STARTUP_PROBE_PORT]: 3000,
  [CreateContainerGroupField.STARTUP_PROBE_HEADERS]: [],
  [CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS]: 5,
  [CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS]: 2,
  [CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS]: 10,
  [CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD]: 100,
  [CreateContainerGroupField.VCPUS]: 4,
  [CreateContainerGroupField.MEMORY]: 30720,
  [CreateContainerGroupField.GPU]: ['ed563892-aacd-40f5-80b7-90c9be6c759b'],
  [CreateContainerGroupField.DISK_SPACE]: 1073741824, // 1 GB in bytes
  [CreateContainerGroupField.PRIORITY]: ContainerGroupPriority.High,
}

/** The default values for the FLUX.1-Schnell (FP8) preset container group. */
export const FluxSchnellPresetValues: Partial<CreateContainerGroupValues> = {
  [CreateContainerGroupField.NAME]: 'flux1-schnell-fp8-comfyui',
  [CreateContainerGroupField.IMAGE_SOURCE]: 'saladtechnologies/comfyui:comfy0.2.0-api1.4.1-flux1-schnell-fp8',
  [CreateContainerGroupField.AUTOSTART_POLICY]: true,
  [CreateContainerGroupField.REPLICA_COUNT]: 3,
  [CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED]: true,
  [CreateContainerGroupField.CONTAINER_GATEWAY_PORT]: 3000,
  [CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]: false,
  [CreateContainerGroupField.CONTAINER_GATEWAY_LOAD_BALANCER]:
    CreateContainerGroupNetworkingLoadBalancerEnum.LeastNumberOfConnections,
  [CreateContainerGroupField.CONTAINER_GATEWAY_LIMIT_SERVER]: false,
  [CreateContainerGroupField.CONTAINER_GATEWAY_CLIENT_REQUEST_TIMEOUT]: 100000,
  [CreateContainerGroupField.CONTAINER_GATEWAY_SERVER_RESPONSE_TIMEOUT]: 100000,
  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: true,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.READINESS_PROBE_PATH]: '/ready',
  [CreateContainerGroupField.READINESS_PROBE_PORT]: 3000,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: [],
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: 0,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: 1,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: 1,
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: 3,
  [CreateContainerGroupField.STARTUP_PROBE_ENABLED]: true,
  [CreateContainerGroupField.STARTUP_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.STARTUP_PROBE_PATH]: '/health',
  [CreateContainerGroupField.STARTUP_PROBE_PORT]: 3000,
  [CreateContainerGroupField.STARTUP_PROBE_HEADERS]: [],
  [CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS]: 10,
  [CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS]: 2,
  [CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS]: 10,
  [CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD]: 100,
  [CreateContainerGroupField.VCPUS]: 4,
  [CreateContainerGroupField.MEMORY]: 30720,
  [CreateContainerGroupField.GPU]: ['ed563892-aacd-40f5-80b7-90c9be6c759b'],
  [CreateContainerGroupField.DISK_SPACE]: 1073741824, // 1 GB in bytes
  [CreateContainerGroupField.PRIORITY]: ContainerGroupPriority.High,
}

/** The default values for the Dreamshaper 8 preset container group. */
export const DreamshaperPresetValues: Partial<CreateContainerGroupValues> = {
  [CreateContainerGroupField.NAME]: 'dreamshaper8-comfyui',
  [CreateContainerGroupField.IMAGE_SOURCE]: 'saladtechnologies/comfyui:comfy0.2.0-api1.4.1-dreamshaper8',
  [CreateContainerGroupField.AUTOSTART_POLICY]: true,
  [CreateContainerGroupField.REPLICA_COUNT]: 3,
  [CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED]: true,
  [CreateContainerGroupField.CONTAINER_GATEWAY_PORT]: 3000,
  [CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]: false,
  [CreateContainerGroupField.CONTAINER_GATEWAY_LOAD_BALANCER]:
    CreateContainerGroupNetworkingLoadBalancerEnum.LeastNumberOfConnections,
  [CreateContainerGroupField.CONTAINER_GATEWAY_LIMIT_SERVER]: false,
  [CreateContainerGroupField.CONTAINER_GATEWAY_CLIENT_REQUEST_TIMEOUT]: 100000,
  [CreateContainerGroupField.CONTAINER_GATEWAY_SERVER_RESPONSE_TIMEOUT]: 100000,
  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: true,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.READINESS_PROBE_PATH]: '/ready',
  [CreateContainerGroupField.READINESS_PROBE_PORT]: 3000,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: [],
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: 0,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: 2,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: 2,
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: 3,
  [CreateContainerGroupField.STARTUP_PROBE_ENABLED]: true,
  [CreateContainerGroupField.STARTUP_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.STARTUP_PROBE_PATH]: '/health',
  [CreateContainerGroupField.STARTUP_PROBE_PORT]: 3000,
  [CreateContainerGroupField.STARTUP_PROBE_HEADERS]: [],
  [CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS]: 5,
  [CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS]: 1,
  [CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS]: 10,
  [CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD]: 100,
  [CreateContainerGroupField.VCPUS]: 4,
  [CreateContainerGroupField.MEMORY]: 12288, // 12 GB in MB
  [CreateContainerGroupField.GPU]: ['cb6c1931-89b6-4f76-976f-54047320ccc6'],
  [CreateContainerGroupField.DISK_SPACE]: 1073741824, // 1 GB in bytes
  [CreateContainerGroupField.PRIORITY]: ContainerGroupPriority.High,
}

/** The default values for the Ollama Llama 3.1 preset container group. */
export const OllamaLlama31PresetValues: Partial<CreateContainerGroupValues> = {
  [CreateContainerGroupField.NAME]: 'ollama-llama31',
  [CreateContainerGroupField.IMAGE_SOURCE]: 'saladtechnologies/ollama-llama3.1-recipe:1.0.0',
  [CreateContainerGroupField.IMAGE_TYPE]: ImageType.PUBLIC,
  [CreateContainerGroupField.AUTOSTART_POLICY]: false,
  [CreateContainerGroupField.REPLICA_COUNT]: 3,
  [CreateContainerGroupField.VCPUS]: 8,
  [CreateContainerGroupField.MEMORY]: 8192, // 8 GB in MB
  [CreateContainerGroupField.GPU]: ['f51baccc-dc95-40fb-a5d1-6d0ee0db31d2'],
  [CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED]: true,
  [CreateContainerGroupField.CONTAINER_GATEWAY_PORT]: 11434,
  [CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]: false,
  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: true,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.READINESS_PROBE_PATH]: '/',
  [CreateContainerGroupField.READINESS_PROBE_PORT]: 11434,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: [],
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: 0,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: 10,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: 10,
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: 10,
  [CreateContainerGroupField.STARTUP_PROBE_ENABLED]: true,
  [CreateContainerGroupField.STARTUP_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.STARTUP_PROBE_PATH]: '/',
  [CreateContainerGroupField.STARTUP_PROBE_PORT]: 11434,
  [CreateContainerGroupField.STARTUP_PROBE_HEADERS]: [],
  [CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS]: 60,
  [CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS]: 3,
  [CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS]: 10,
  [CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD]: 10,
}

/** The default values for the TGI Mistral 7B Instruct preset container group. */
export const TgiMistral7bInstructPresetValues: Partial<CreateContainerGroupValues> = {
  [CreateContainerGroupField.NAME]: 'tgi-mistral7b-instruct',
  [CreateContainerGroupField.IMAGE_SOURCE]: 'ghcr.io/huggingface/text-generation-inference:2.1.1',
  [CreateContainerGroupField.IMAGE_TYPE]: ImageType.PUBLIC,
  [CreateContainerGroupField.AUTOSTART_POLICY]: false,
  [CreateContainerGroupField.REPLICA_COUNT]: 3,
  [CreateContainerGroupField.VCPUS]: 8,
  [CreateContainerGroupField.MEMORY]: 16384, // 16 GB in MB
  [CreateContainerGroupField.GPU]: [
    'a5db5c50-cbcb-4596-ae80-6a0c8090d80f',
    'ed563892-aacd-40f5-80b7-90c9be6c759b',
    '9998fe42-04a5-4807-b3a5-849943f16c38',
  ],
  [CreateContainerGroupField.DISK_SPACE]: 26843545600, // 25 GB in bytes
  [CreateContainerGroupField.ENVIRONMENT_VARIABLES]: [
    { key: 'MODEL_ID', value: 'mistralai/Mistral-7B-Instruct-v0.3' },
    { key: 'PORT', value: '3000' },
    { key: 'HOSTNAME', value: '::' },
    { key: 'HUGGING_FACE_HUB_TOKEN', value: '' },
  ],
  [CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED]: true,
  [CreateContainerGroupField.CONTAINER_GATEWAY_PORT]: 3000,
  [CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]: false,
  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: true,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.READINESS_PROBE_PATH]: '/health',
  [CreateContainerGroupField.READINESS_PROBE_PORT]: 3000,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: [],
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: 0,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: 10,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: 10,
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: 3,
}

/** The default values for the YOLOv8-M preset container group. */
export const Yolov8MPresetValues: Partial<CreateContainerGroupValues> = {
  [CreateContainerGroupField.NAME]: 'yolov8-m',
  [CreateContainerGroupField.IMAGE_SOURCE]: 'saladtechnologies/yolov8-recipe:1.0.0',
  [CreateContainerGroupField.IMAGE_TYPE]: ImageType.PUBLIC,
  [CreateContainerGroupField.AUTOSTART_POLICY]: false,
  [CreateContainerGroupField.REPLICA_COUNT]: 3,
  [CreateContainerGroupField.VCPUS]: 8,
  [CreateContainerGroupField.MEMORY]: 16384, // 16 GB in MB
  [CreateContainerGroupField.GPU]: ['2b73eef8-be49-4667-8fc0-5c0cb127cfe0'],
  [CreateContainerGroupField.DISK_SPACE]: 10737418240, // 10 GB in bytes
  [CreateContainerGroupField.PRIORITY]: ContainerGroupPriority.High,
  [CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED]: true,
  [CreateContainerGroupField.CONTAINER_GATEWAY_PORT]: 80,
  [CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]: false,
  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: true,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.READINESS_PROBE_PATH]: '/health',
  [CreateContainerGroupField.READINESS_PROBE_PORT]: 80,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: [],
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: 0,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: 2,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: 2,
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: 3,
  [CreateContainerGroupField.STARTUP_PROBE_ENABLED]: true,
  [CreateContainerGroupField.STARTUP_PROBE_PROTOCOL]: ProtocolOptions.HTTP1X,
  [CreateContainerGroupField.STARTUP_PROBE_PATH]: '/health',
  [CreateContainerGroupField.STARTUP_PROBE_PORT]: 80,
  [CreateContainerGroupField.STARTUP_PROBE_HEADERS]: [],
  [CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS]: 15,
  [CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS]: 3,
  [CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS]: 10,
  [CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD]: 2,
  [CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD]: 20,
}
