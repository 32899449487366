import { yupResolver } from '@hookform/resolvers/yup'
import type {
  ContainerGroup,
  Queue,
  RamOption,
  StorageOption,
  UpdateContainerGroup,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { type FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { EstimatedCost } from '../../components/containerGroups/EstimatedCost'
import type { ClientGpuOptions } from '../../features/gpuClasses/models'
import { useFixedForm } from '../../hooks'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { ContainerGroupInstancesQuotaExceededModal } from './components/ContainerGroupInstancesQuotaExceededModal'
import { EditContainerGroupMainContent } from './components/EditContainerGroupMainContent'
import { type EditContainerGroupValues } from './models'
import { useEditContainerGroupFormValidation } from './useEditContainerGroupFormValidation'
import { useGetEstimatedCost } from './useGetEstimatedCost'
import { configureDefaultValues, handleSubmitEditContainerGroup } from './utils'

export interface EditContainerGroupPageProps {
  /** The path to "Container Groups" */
  containerGroupDetailsPath: string
  /** The container group the user has selected to edit */
  currentContainerGroup: ContainerGroup
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The gpu options the user has to select from */
  gpuOptions: ClientGpuOptions
  /** The flag indicating the container group instance modal exceeded showing state */
  isContainerGroupInstancesQuotaExceededModalShowing: boolean
  /** The flag indicating if the container group priority input is enabled. */
  isContainerGroupPriorityEnabled: boolean
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** Indicates if saving the edited container details is in progress */
  isEditContainerGroupDetailsPending: boolean
  /** The flag indicating if the job queue autoscaler is enabled. */
  isJobQueueAutoscalerEnabled: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The link to the typeform for requesting an increase in the container group instance quota limit. */
  linkToRequestIncreaseForContainerGroupInstancesQuotas: string
  /** The maximum replica count allowed for the organization. */
  maxReplicaCount: number
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The callback executed when there is an update to the container group instances exceeded modal showing state */
  onSetContainerGroupInstancesQuotaExceededModalShowingState: (showing: boolean) => void
  /** The callback executed when the user wishes to deploy the container group they just created. */
  onSubmit: (values: UpdateContainerGroup) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The available job queues to select from for a container group. */
  queues: Queue[]
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const EditContainerGroupPage: FunctionComponent<EditContainerGroupPageProps> = ({
  currentContainerGroup,
  currentOrganization,
  currentProject,
  gpuOptions,
  isContainerGroupInstancesQuotaExceededModalShowing,
  isContainerGroupPriorityEnabled,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isEditContainerGroupDetailsPending,
  isJobQueueAutoscalerEnabled,
  isLeftColumnOpen,
  linkToRequestIncreaseForContainerGroupInstancesQuotas,
  maxReplicaCount,
  onCloseLeftDrawer,
  onDeleteProject,
  onProjectChange,
  onRecordMixpanelElementClickedEvent,
  onSetContainerGroupInstancesQuotaExceededModalShowingState,
  onSubmit,
  projects,
  queues,
  ramOptions,
  storageOptions,
}) => {
  const defaultValues = configureDefaultValues(currentContainerGroup)
  const {
    clearErrors,
    control,
    formState: { dirtyFields },
    getFieldState,
    handleSubmit,
    resetField,
    setValue,
    trigger,
  } = useFixedForm<EditContainerGroupValues>({
    defaultValues,
    onSubmit: (values) => {
      handleSubmitEditContainerGroup(values, dirtyFields, onSubmit)
    },
    resolver: yupResolver(useEditContainerGroupFormValidation(maxReplicaCount)),
    mode: 'onChange',
    shouldFocusError: false,
  })
  const estimateCostValues = useGetEstimatedCost(control, isContainerGroupPriorityEnabled, gpuOptions, ramOptions)
  const isEditFormDirty = Object.keys(dirtyFields).length > 0

  return (
    <>
      <ThreeColumnLayout
        LeftColumn={
          <LeftNavigationBar
            currentOrganization={currentOrganization}
            currentProject={currentProject}
            isDeleteProjectPending={isDeleteProjectPending}
            isDeleteProjectSuccessful={isDeleteProjectSuccessful}
            onDeleteProject={onDeleteProject}
            onProjectChange={onProjectChange}
            projects={projects}
          />
        }
        MainColumn={
          <EditContainerGroupMainContent
            allocatedResources={estimateCostValues.allocatedResources}
            clearErrors={clearErrors}
            control={control}
            currentContainerGroup={currentContainerGroup}
            gpuOptions={gpuOptions}
            isContainerGroupPriorityEnabled={isContainerGroupPriorityEnabled}
            isEditFormDirty={isEditFormDirty}
            isEditContainerGroupDetailsPending={isEditContainerGroupDetailsPending}
            isJobQueueAutoscalerEnabled={isJobQueueAutoscalerEnabled}
            linkToRequestIncreaseForContainerGroupInstancesQuotas={
              linkToRequestIncreaseForContainerGroupInstancesQuotas
            }
            maxReplicaCount={maxReplicaCount}
            onHandleSubmit={handleSubmit}
            onGetFieldState={getFieldState}
            onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
            queues={queues}
            ramOptions={ramOptions}
            replicaCount={estimateCostValues.replicaCount}
            resetField={resetField}
            setValue={setValue}
            storageOptions={storageOptions}
            trigger={trigger}
          />
        }
        isLeftColumnOpen={isLeftColumnOpen}
        isMainColumnWithoutLayoutStyles
        isMainColumnWithoutScrollStyles
        isRightColumnShowcase
        onCloseLeftDrawer={onCloseLeftDrawer}
        RightColumn={
          <div className="p-12">
            <EstimatedCost
              allocatedResources={estimateCostValues.allocatedResources}
              priority={estimateCostValues.priority}
              replicaCount={estimateCostValues.replicaCount}
            />
          </div>
        }
      />
      {isContainerGroupInstancesQuotaExceededModalShowing && (
        <ContainerGroupInstancesQuotaExceededModal
          linkToRequestIncreaseForContainerGroupInstancesQuotas={linkToRequestIncreaseForContainerGroupInstancesQuotas}
          onCloseWindow={() => onSetContainerGroupInstancesQuotaExceededModalShowingState(false)}
        />
      )}
    </>
  )
}
