import { defineMessages } from 'react-intl'

export const CreateInferenceEndpointJobInferenceEndpointNotFoundErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your inference endpoint job because the inference endpoint could not be found. Please {contact_support} for further assistance.',
    id: 'Qzq+w+z9',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an inference endpoint job because the inference endpoint could not be found.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'RqJIefYN',
    description:
      'The text shown for the contact support link in the create inference endpoint job error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating inference endpoint job',
    id: 'GzgvNy7o',
    description:
      'The toast notification title shown when we encounter an error on their request to create an inference endpoint job because the inference endpoint could not be found.',
  },
})
