import { useCallback, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useMatches, useParams } from 'react-router'
import { trackMixpanelElementClickedEvent, trackMixpanelEvent } from '../../../features/analytics/analyticsSlice'
import { selectIsHelpScoutBeaconFailedToOpenModalOpen } from '../../../features/helpScout/helpScoutSelectors'
import { setIsHelpScoutBeaconFailedToOpenModalOpen } from '../../../features/helpScout/helpScoutSlice'
import { selectLastViewedResourceByOrganization } from '../../../features/lastResourceViewed/lastResourceViewedSelectors'
import { selectLatestProject } from '../../../features/projects/projectsSelectors'
import {
  getContainerGroupsPagePath,
  getInferenceEndpointDetailsPagePath,
  getInferenceEndpointsMarketplacePagePath,
  getJobQueuesPagePath,
  getOrganizationTeamPagePath,
  getOrganizationWebhookSecretKeyPagePath,
} from '../../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../../store'
import { contactSupportViaHelpScoutAsks } from '../../../utils/helpScout'
import { HelpScoutBeaconFailedToLoadModal } from '../../../utils/helpScout/components'
import { MenuItem } from '../../block/MenuItem'
import { LeftNavigationBarMessages } from '../messages/LeftNavigationBarMessages'

export const MenuItems: FunctionComponent = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const matches = useMatches()
  const { containerGroupName, inferenceEndpointName, jobQueueName, organizationName, projectName } = useParams()
  let isInferenceEndpointsPage = false

  if (
    (organizationName && pathname === getInferenceEndpointsMarketplacePagePath(organizationName)) ||
    (organizationName &&
      inferenceEndpointName &&
      pathname === getInferenceEndpointDetailsPagePath(organizationName, inferenceEndpointName))
  ) {
    isInferenceEndpointsPage = true
  }

  const isHelpScoutBeaconFailedToLoadModalOpen = useAppSelector(selectIsHelpScoutBeaconFailedToOpenModalOpen)
  const lastViewedProjectName = useAppSelector((state) =>
    selectLastViewedResourceByOrganization(state, organizationName || ''),
  )?.projectName
  const lastCreatedProjectName = useAppSelector((state) => selectLatestProject(state, organizationName || ''))?.name
  const defaultProjectName = lastViewedProjectName ?? lastCreatedProjectName
  const projName = isInferenceEndpointsPage ? defaultProjectName : projectName

  const pageRoutePath = matches?.[0]?.id

  const onRecordMixpanelElementClickedEvent = useCallback(
    (label: string) => {
      pageRoutePath &&
        dispatch(
          trackMixpanelElementClickedEvent({
            label,
            path: pageRoutePath,
            organizationName,
            projectName,
            resourceName: containerGroupName,
            jobQueueName,
          }),
        )
    },
    [containerGroupName, dispatch, jobQueueName, organizationName, pageRoutePath, projectName],
  )

  const handleHelpScoutFailedToOpenMixpanelEventTrack = useCallback(() => {
    pageRoutePath &&
      dispatch(
        trackMixpanelEvent({
          event: 'Event', // TODO: Change this to a more descriptive event name
          properties: {
            label: LeftNavigationBarMessages.helpScoutWidgetFailedViaContactSupportMixpanelEventLabeL.defaultMessage,
            path: pageRoutePath,
            organizationName,
            projectName,
            resourceName: containerGroupName,
            jobQueueName,
          },
        }),
      )
  }, [containerGroupName, dispatch, jobQueueName, organizationName, pageRoutePath, projectName])

  const onSetIsHelpScoutBeaconFailedToLoadModalOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsHelpScoutBeaconFailedToOpenModalOpen({ isOpen }))
    },
    [dispatch],
  )

  const handleContactSupportClick = () => {
    onRecordMixpanelElementClickedEvent(LeftNavigationBarMessages.contactSupportButtonLabel.defaultMessage)
    contactSupportViaHelpScoutAsks(
      onSetIsHelpScoutBeaconFailedToLoadModalOpen,
      handleHelpScoutFailedToOpenMixpanelEventTrack,
    )
  }

  const handleDocumentationClick = () => {
    onRecordMixpanelElementClickedEvent(LeftNavigationBarMessages.documentationNavigationLabel.defaultMessage)
  }

  return (
    <>
      <ul>
        {organizationName === undefined && projName === undefined && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-layer-group"
                label={intl.formatMessage(LeftNavigationBarMessages.organizationsNavigationLabel)}
                path="/organizations"
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-cog"
                label={intl.formatMessage(LeftNavigationBarMessages.accountSettingsNavigationLabel)}
                path="/account"
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-code"
                label={intl.formatMessage(LeftNavigationBarMessages.apiAccessNavigationLabel)}
                path="/api-key"
                variant="left-nav"
              />
            </li>
          </>
        )}

        {organizationName && projName && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-code"
                label={intl.formatMessage(LeftNavigationBarMessages.inferenceEndpointsNavigationLabel)}
                path={getInferenceEndpointsMarketplacePagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-boxes"
                label={intl.formatMessage(LeftNavigationBarMessages.containerGroupsNavigationLabel)}
                path={getContainerGroupsPagePath(organizationName, projName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-window-restore"
                isFeaturePreview
                label={intl.formatMessage(LeftNavigationBarMessages.jobQueuesNavigationLabel)}
                path={getJobQueuesPagePath(organizationName, projName)}
                variant="left-nav"
              />
            </li>
          </>
        )}

        {organizationName && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-key"
                label={intl.formatMessage(LeftNavigationBarMessages.webhookSecretKeyNavigationLabel)}
                path={getOrganizationWebhookSecretKeyPagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-users"
                label={intl.formatMessage(LeftNavigationBarMessages.teamNavigationLabel)}
                path={getOrganizationTeamPagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-credit-card"
                label={intl.formatMessage(LeftNavigationBarMessages.billingAndUsageNavigationLabel)}
                path={`/organizations/${organizationName}/billing`}
                variant="left-nav"
              />
            </li>
          </>
        )}

        <li>
          <MenuItem
            iconClassName="fa-solid fa-book"
            label={intl.formatMessage(LeftNavigationBarMessages.documentationNavigationLabel)}
            externalLink="https://docs.salad.com/introduction"
            onClick={handleDocumentationClick}
            variant="left-nav"
          />
        </li>
        <li>
          <MenuItem
            iconClassName="fa-solid fa-headset"
            label={intl.formatMessage(LeftNavigationBarMessages.contactSupportButtonLabel)}
            onClick={handleContactSupportClick}
            variant="left-nav"
          />
        </li>
      </ul>
      {isHelpScoutBeaconFailedToLoadModalOpen && (
        <HelpScoutBeaconFailedToLoadModal onClose={() => onSetIsHelpScoutBeaconFailedToLoadModalOpen(false)} />
      )}
    </>
  )
}
