import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  email: 'luke.skywalker@salad.com',
  isLeftColumnShowing: false,
  organization: 'Salad Technologies',
}

export const mockNavigationBarSlice = createSlice({
  name: 'navigationBar',
  initialState,
  reducers: {
    setLeftColumnShowing(state, action: PayloadAction<{ showing: boolean }>) {
      state.isLeftColumnShowing = action.payload.showing
    },
  },
})

export const { setLeftColumnShowing } = mockNavigationBarSlice.actions
