import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const createOrganizationWizardInitialState: {
  createOrganizationWizardCurrentPage: number
} = {
  createOrganizationWizardCurrentPage: 0,
}

export const mockCreateOrganizationWizardSlice = createSlice({
  name: 'createOrganizationWizard',
  initialState: createOrganizationWizardInitialState,
  reducers: {
    setCreateOrganizationWizardCurrentPage(state, action: PayloadAction<{ page: number }>) {
      state.createOrganizationWizardCurrentPage = action.payload.page
    },
  },
})

export const { setCreateOrganizationWizardCurrentPage } = mockCreateOrganizationWizardSlice.actions
