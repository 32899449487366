import type {
  ContainerGroup,
  ContainerGroupLivenessProbe,
  ContainerGroupReadinessProbe,
  ContainerGroupStartupProbe,
  ContainerLogging,
  ContainerLoggingHttp,
  CreateContainer,
  CreateContainerRegistryAuthentication,
  HttpHeadersInner,
  QueueAutoscaler,
  UpdateContainerGroup,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  ContainerLoggingHttpCompressionEnum,
  ContainerLoggingHttpFormatEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import flattenDeep from 'lodash/flattenDeep'
import type { FieldValues, UseFormGetFieldState } from 'react-hook-form'
import { ExternalLoggingService } from '../../components/containerGroups/models'
import CPUCoreOptions from '../../utils/CPUCoreOptions.json'
import { getDefaultRamOption } from '../../utils/ramOptions'
import {
  editAutoscalingFieldsList,
  editLivenessProbeFieldsList,
  editReadinessProbeFieldsList,
  editStartupProbeFieldsList,
} from './components/EditHealthProbeFields/utils'
import { externalLoggingFieldsList } from './components/EditLoggingFields/utils'
import type { EditContainerGroupValues, HTTPExternalLoggingDefaultValues, HealthProbeDefaultValues } from './models'
import {
  EditContainerGroupField,
  EditContainerGroupFormSectionIdAttributes,
  ImageType,
  PrivateRegistryProvider,
  ProtocolOptions,
} from './models'

/**
 * Configures the default values for the startup health probe fields for the Edit Container Group form.
 *
 * @param healthProbe The health probe.
 */
const configureStartupHealthProbeDefaultValues = (
  healthProbe:
    | ContainerGroupLivenessProbe
    | ContainerGroupReadinessProbe
    | ContainerGroupStartupProbe
    | null
    | undefined,
): HealthProbeDefaultValues => {
  const command = healthProbe?.exec?.command[0]
  let commandArguments = undefined
  if (healthProbe?.exec?.command && healthProbe?.exec?.command.length > 1) {
    commandArguments = healthProbe.exec.command.slice(1).map((arg) => ({ argument: arg }))
  }

  return {
    command,
    commandArguments,
    enabled: healthProbe != null,
    failureThreshold: healthProbe?.failureThreshold || 1200,
    headers: healthProbe?.http?.headers,
    initialDelaySeconds: healthProbe?.initialDelaySeconds || 0,
    path: healthProbe?.http?.path,
    periodSeconds: healthProbe?.periodSeconds || 3,
    port: healthProbe?.http?.port || healthProbe?.grpc?.port || healthProbe?.tcp?.port || 1,
    protocol: healthProbe?.http
      ? ProtocolOptions.HTTP1X
      : healthProbe?.exec
        ? ProtocolOptions.EXEC
        : healthProbe?.tcp
          ? ProtocolOptions.TCP
          : healthProbe?.grpc
            ? ProtocolOptions.GRPC
            : undefined,
    service: healthProbe?.grpc?.service,
    successThreshold: healthProbe?.successThreshold || 2,
    timeoutSeconds: healthProbe?.timeoutSeconds || 10,
  }
}

/**
 * Configures the default values for the liveness health probe fields for the Edit Container Group form.
 *
 * @param healthProbe The health probe.
 */
const configureLivenessHealthProbeDefaultValues = (
  healthProbe:
    | ContainerGroupLivenessProbe
    | ContainerGroupReadinessProbe
    | ContainerGroupStartupProbe
    | null
    | undefined,
): HealthProbeDefaultValues => {
  const command = healthProbe?.exec?.command[0]
  let commandArguments = undefined
  if (healthProbe?.exec?.command && healthProbe?.exec?.command.length > 1) {
    commandArguments = healthProbe.exec.command.slice(1).map((arg) => ({ argument: arg }))
  }

  return {
    command,
    commandArguments,
    enabled: healthProbe != null,
    failureThreshold: healthProbe?.failureThreshold || 3,
    headers: healthProbe?.http?.headers,
    initialDelaySeconds: healthProbe?.initialDelaySeconds || 0,
    path: healthProbe?.http?.path,
    periodSeconds: healthProbe?.periodSeconds || 10,
    port: healthProbe?.http?.port || healthProbe?.grpc?.port || healthProbe?.tcp?.port || 1,
    protocol: healthProbe?.http
      ? ProtocolOptions.HTTP1X
      : healthProbe?.exec
        ? ProtocolOptions.EXEC
        : healthProbe?.tcp
          ? ProtocolOptions.TCP
          : healthProbe?.grpc
            ? ProtocolOptions.GRPC
            : undefined,
    service: healthProbe?.grpc?.service,
    successThreshold: healthProbe?.successThreshold || 1,
    timeoutSeconds: healthProbe?.timeoutSeconds || 30,
  }
}

/**
 * Configures the default values for the readiness health probe fields for the Edit Container Group form.
 *
 * @param healthProbe The health probe.
 */
const configureReadinessHealthProbeDefaultValues = (
  healthProbe:
    | ContainerGroupLivenessProbe
    | ContainerGroupReadinessProbe
    | ContainerGroupStartupProbe
    | null
    | undefined,
): HealthProbeDefaultValues => {
  const command = healthProbe?.exec?.command[0]
  let commandArguments = undefined
  if (healthProbe?.exec?.command && healthProbe?.exec?.command.length > 1) {
    commandArguments = healthProbe.exec.command.slice(1).map((arg) => ({ argument: arg }))
  }

  return {
    command,
    commandArguments,
    enabled: healthProbe != null,
    failureThreshold: healthProbe?.failureThreshold || 3,
    headers: healthProbe?.http?.headers,
    initialDelaySeconds: healthProbe?.initialDelaySeconds || 0,
    path: healthProbe?.http?.path,
    periodSeconds: healthProbe?.periodSeconds || 1,
    port: healthProbe?.http?.port || healthProbe?.grpc?.port || healthProbe?.tcp?.port || 1,
    protocol: healthProbe?.http
      ? ProtocolOptions.HTTP1X
      : healthProbe?.exec
        ? ProtocolOptions.EXEC
        : healthProbe?.tcp
          ? ProtocolOptions.TCP
          : healthProbe?.grpc
            ? ProtocolOptions.GRPC
            : undefined,
    service: healthProbe?.grpc?.service,
    successThreshold: healthProbe?.successThreshold || 1,
    timeoutSeconds: healthProbe?.timeoutSeconds || 1,
  }
}

/**
 * Configures the default values for the HTTP logging fields for the Create Container Group form.
 *
 * @param httpLogging The HTTP Logging values.
 */
const configureExternalLoggingHttpDefaultValues = (
  httpLogging: ContainerLoggingHttp | null | undefined,
): HTTPExternalLoggingDefaultValues => {
  return {
    compression: httpLogging?.compression || ContainerLoggingHttpCompressionEnum.Gzip,
    format: httpLogging?.format ?? ContainerLoggingHttpFormatEnum.JsonLines,
    headers: httpLogging?.headers ?? undefined,
    host: httpLogging?.host ?? '127.0.0.1',
    password: httpLogging?.password ?? undefined,
    path: httpLogging?.path ?? undefined,
    port: httpLogging?.port || 443,
    user: httpLogging?.user ?? undefined,
  }
}

/**
 * Configures the default values for the Edit Container Group form, this will either supply the default values for a
 * blank field or the values from the container group the user wishes to edit.
 *
 * @param duplicateContainerGroup The container group the user wishes to edit.
 */
export const configureDefaultValues = (duplicateContainerGroup: ContainerGroup): EditContainerGroupValues => {
  const livenessProbeDefaultValues = configureLivenessHealthProbeDefaultValues(duplicateContainerGroup?.livenessProbe)
  const readinessProbeDefaultValues = configureReadinessHealthProbeDefaultValues(
    duplicateContainerGroup?.readinessProbe,
  )
  const startupProbeDefaultValues = configureStartupHealthProbeDefaultValues(duplicateContainerGroup?.startupProbe)
  const externalLoggingHttpDefaultValues = configureExternalLoggingHttpDefaultValues(
    duplicateContainerGroup?.container.logging?.http,
  )
  const externalLoggingService = duplicateContainerGroup?.container.logging?.newRelic
    ? ExternalLoggingService.NEW_RELIC
    : duplicateContainerGroup?.container.logging?.splunk
      ? ExternalLoggingService.SPLUNK
      : duplicateContainerGroup?.container.logging?.tcp
        ? ExternalLoggingService.TCP
        : duplicateContainerGroup?.container.logging?.datadog
          ? ExternalLoggingService.DATADOG
          : duplicateContainerGroup?.container.logging?.http
            ? ExternalLoggingService.HTTP
            : duplicateContainerGroup?.container.logging?.axiom
              ? ExternalLoggingService.AXIOM
              : undefined
  const environmentVariables = duplicateContainerGroup?.container.environmentVariables
  const environmentVariablesArray = environmentVariables
    ? Object.entries(environmentVariables || {}).map(([key, value]) => {
        return { key, value }
      })
    : undefined

  const duplicateContainerGroupMemory = duplicateContainerGroup?.container.resources.memory
  const newRelicHostDefaultValue = 'https://log-api.newrelic.com/log/v1'
  const axiomHostDefaultValue = 'api.axiom.co'
  const dataDogHostDefaultValue = 'http-intake.logs.datadoghq.com/'
  const defaultCoreOption =
    CPUCoreOptions.options.find((option) => option.cores === 8)?.cores || CPUCoreOptions.options[0]?.cores

  let commandArguments = undefined
  if (duplicateContainerGroup?.container.command && duplicateContainerGroup?.container.command.length > 1) {
    commandArguments = duplicateContainerGroup.container.command.slice(1).map((arg) => ({ argument: arg }))
  }

  const isAutoscalingEnabled = !!duplicateContainerGroup?.queueAutoscaler?.minReplicas

  return {
    autoscalingDesiredQueueLength: duplicateContainerGroup?.queueAutoscaler?.desiredQueueLength,
    autoscalingEnabled: isAutoscalingEnabled,
    autoscalingMaximumDownscalePerMinute: duplicateContainerGroup?.queueAutoscaler?.maxDownscalePerMinute,
    autoscalingMaximumReplicas: duplicateContainerGroup?.queueAutoscaler?.maxReplicas,
    autoscalingMaximumUpscalePerMinute: duplicateContainerGroup?.queueAutoscaler?.maxUpscalePerMinute,
    autoscalingMinimumReplicas: duplicateContainerGroup?.queueAutoscaler?.minReplicas,
    autoscalingPeriod: duplicateContainerGroup?.queueAutoscaler?.pollingPeriod,
    awsElasticAccessKeyId: undefined,
    awsElasticSecretAccessKey: undefined,
    axiomApiKey: duplicateContainerGroup?.container.logging?.axiom?.apiToken,
    axiomHost: duplicateContainerGroup?.container.logging?.axiom?.host || axiomHostDefaultValue,
    axiomDatasetName: duplicateContainerGroup?.container.logging?.axiom?.dataset,
    azurePassword: undefined,
    azureUsername: undefined,
    // autostartPolicy: duplicateContainerGroup?.autostartPolicy || true,
    command: duplicateContainerGroup?.container?.command?.[0] ?? undefined,
    commandArguments,
    datadogApi: duplicateContainerGroup?.container.logging?.datadog?.apiKey,
    datadogHost: duplicateContainerGroup?.container.logging?.datadog?.host || dataDogHostDefaultValue,
    datadogTags: duplicateContainerGroup?.container.logging?.datadog?.tags || [],
    diskSpace: duplicateContainerGroup?.container.resources.storageAmount || undefined,
    displayName: duplicateContainerGroup?.displayName,
    dockerHubPersonalAccessToken: undefined,
    dockerHubUsername: undefined,
    environmentVariables: environmentVariablesArray,
    externalLoggingService: externalLoggingService,
    gitHubPersonalAccessToken: undefined,
    gitHubUsername: undefined,
    googleArtifactJsonKey: undefined,
    googleContainerJsonKey: undefined,
    gpu: duplicateContainerGroup?.container.resources.gpuClasses ?? undefined,
    httpCompression: externalLoggingHttpDefaultValues.compression,
    httpFormat: externalLoggingHttpDefaultValues.format,
    httpHeaders: externalLoggingHttpDefaultValues.headers,
    httpHost: externalLoggingHttpDefaultValues.host,
    httpPassword: externalLoggingHttpDefaultValues.password,
    httpPath: externalLoggingHttpDefaultValues.path,
    httpPort: externalLoggingHttpDefaultValues.port,
    httpUser: externalLoggingHttpDefaultValues.user,
    imageSource: undefined,
    imageType: ImageType.PUBLIC,
    jobQueue: duplicateContainerGroup?.queueConnection?.queueName ?? undefined,
    jobQueuePath: duplicateContainerGroup?.queueConnection?.path ?? undefined,
    jobQueuePort: duplicateContainerGroup?.queueConnection?.port ?? undefined,
    livenessProbeCommand: livenessProbeDefaultValues.command,
    livenessProbeCommandArguments: livenessProbeDefaultValues.commandArguments,
    livenessProbeEnabled: livenessProbeDefaultValues.enabled,
    livenessProbeFailureThreshold: livenessProbeDefaultValues.failureThreshold,
    livenessProbeHeaders: livenessProbeDefaultValues.headers,
    livenessProbeInitialDelaySeconds: livenessProbeDefaultValues.initialDelaySeconds,
    livenessProbePath: livenessProbeDefaultValues.path,
    livenessProbePeriodSeconds: livenessProbeDefaultValues.periodSeconds,
    livenessProbePort: livenessProbeDefaultValues.port,
    livenessProbeProtocol: livenessProbeDefaultValues.protocol,
    livenessProbeService: livenessProbeDefaultValues.service,
    livenessProbeSuccessThreshold: livenessProbeDefaultValues.successThreshold,
    livenessProbeTimeoutSeconds: livenessProbeDefaultValues.timeoutSeconds,
    memory: duplicateContainerGroupMemory ? duplicateContainerGroupMemory : getDefaultRamOption(),
    containerGatewayRequiresAuthentication: duplicateContainerGroup?.networking?.auth || false,
    containerGatewayEnabled: duplicateContainerGroup?.networking != null,
    containerGatewayPort: duplicateContainerGroup?.networking?.port || 1,
    newRelicHost: duplicateContainerGroup?.container.logging?.newRelic?.host ?? newRelicHostDefaultValue,
    newRelicIngestionKey: duplicateContainerGroup?.container.logging?.newRelic?.ingestionKey,
    priority: duplicateContainerGroup?.container.priority || undefined,
    privateRegistryProvider: undefined,
    quayPassword: undefined,
    quayUsername: undefined,
    readinessProbeCommand: readinessProbeDefaultValues.command,
    readinessProbeCommandArguments: readinessProbeDefaultValues.commandArguments,
    readinessProbeEnabled: readinessProbeDefaultValues.enabled,
    readinessProbeFailureThreshold: readinessProbeDefaultValues.failureThreshold,
    readinessProbeHeaders: readinessProbeDefaultValues.headers,
    readinessProbeInitialDelaySeconds: readinessProbeDefaultValues.initialDelaySeconds,
    readinessProbePath: readinessProbeDefaultValues.path,
    readinessProbePeriodSeconds: readinessProbeDefaultValues.periodSeconds,
    readinessProbePort: readinessProbeDefaultValues.port,
    readinessProbeProtocol: readinessProbeDefaultValues.protocol,
    readinessProbeService: readinessProbeDefaultValues.service,
    readinessProbeSuccessThreshold: readinessProbeDefaultValues.successThreshold,
    readinessProbeTimeoutSeconds: readinessProbeDefaultValues.timeoutSeconds,
    replicaCount: duplicateContainerGroup?.replicas,
    selfHostedPassword: undefined,
    selfHostedUsername: undefined,
    splunkHost: duplicateContainerGroup?.container.logging?.splunk?.host,
    splunkToken: duplicateContainerGroup?.container.logging?.splunk?.token,
    startupProbeCommand: startupProbeDefaultValues.command,
    startupProbeCommandArguments: startupProbeDefaultValues.commandArguments,
    startupProbeEnabled: startupProbeDefaultValues.enabled,
    startupProbeFailureThreshold: startupProbeDefaultValues.failureThreshold,
    startupProbeHeaders: startupProbeDefaultValues.headers,
    startupProbeInitialDelaySeconds: startupProbeDefaultValues.initialDelaySeconds,
    startupProbePath: startupProbeDefaultValues.path,
    startupProbePeriodSeconds: startupProbeDefaultValues.periodSeconds,
    startupProbePort: startupProbeDefaultValues.port,
    startupProbeProtocol: startupProbeDefaultValues.protocol,
    startupProbeService: startupProbeDefaultValues.service,
    startupProbeSuccessThreshold: startupProbeDefaultValues.successThreshold,
    startupProbeTimeoutSeconds: startupProbeDefaultValues.timeoutSeconds,
    tcpHost: duplicateContainerGroup?.container.logging?.tcp?.host,
    tcpPort: duplicateContainerGroup?.container.logging?.tcp?.port || 1,
    vcpus: duplicateContainerGroup?.container.resources.cpu || defaultCoreOption || 1,
  }
}

/**
 * There are multiple options (`DockerHub`, `Quay`, and `Github`) that we provide for the user to select from when
 * choosing a private registry provider, that we treat the same way when we pass along the input values to the API. This
 * function takes the two required values and returns the appropriate authentication object to be passed along to the
 * API.
 *
 * @param username The username for the private registry provider.
 * @param password The password for the private registry provider.
 */
const configureDockerHubRegistryAuthentication = (
  username: string,
  password: string,
): CreateContainerRegistryAuthentication => {
  return {
    dockerHub: {
      username: username,
      personalAccessToken: password,
    },
  }
}

/**
 * There are two options (`Azure` and `Self-Hosted`) that we provide for the user to select from when choosing a private
 * registry provider, that we treat the same way when we pass along the input values to the API. This function takes the
 * two required values and returns the appropriate authentication object to be passed along to the API.
 *
 * @param username The username for the private registry provider.
 * @param password The password for the private registry provider.
 */
const configureBasicRegistryAuthentication = (
  username: string,
  password: string,
): CreateContainerRegistryAuthentication => {
  return {
    basic: {
      username: username,
      password: password,
    },
  }
}

const configureRegistryAuthentication = (
  imageType: ImageType,
  privateRegistryProvider: PrivateRegistryProvider | undefined,
  dockerHubUsername: string | undefined,
  dockerHubPassword: string | undefined,
  gitHubUsername: string | undefined,
  gitHubPassword: string | undefined,
  quayUsername: string | undefined,
  quayPassword: string | undefined,
  awsElasticAccessKeyID: string | undefined,
  awsElasticSecretAccessKey: string | undefined,
  selfHostedUsername: string | undefined,
  selfHostedPassword: string | undefined,
  azureUsername: string | undefined,
  azurePassword: string | undefined,
): CreateContainerRegistryAuthentication | null => {
  if (imageType === ImageType.PUBLIC) {
    return null
  }

  if (privateRegistryProvider === PrivateRegistryProvider.DOCKER_HUB && dockerHubUsername && dockerHubPassword) {
    return configureDockerHubRegistryAuthentication(dockerHubUsername, dockerHubPassword)
  }

  if (privateRegistryProvider === PrivateRegistryProvider.GITHUB_CONTAINER && gitHubUsername && gitHubPassword) {
    return configureDockerHubRegistryAuthentication(gitHubUsername, gitHubPassword)
  }

  if (privateRegistryProvider === PrivateRegistryProvider.QUAY_CONTAINER && quayUsername && quayPassword) {
    return configureDockerHubRegistryAuthentication(quayUsername, quayPassword)
  }

  if (
    privateRegistryProvider === PrivateRegistryProvider.AWS_ECR &&
    awsElasticAccessKeyID &&
    awsElasticSecretAccessKey
  ) {
    return {
      awsEcr: {
        accessKeyId: awsElasticAccessKeyID,
        secretAccessKey: awsElasticSecretAccessKey,
      },
    }
  }

  if (privateRegistryProvider === PrivateRegistryProvider.SELF_HOSTED && selfHostedUsername && selfHostedPassword) {
    return configureBasicRegistryAuthentication(selfHostedUsername, selfHostedPassword)
  }

  if (privateRegistryProvider === PrivateRegistryProvider.AZURE_CONTAINER && azureUsername && azurePassword) {
    return configureBasicRegistryAuthentication(azureUsername, azurePassword)
  }

  return null
}

/**
 * Returns a boolean value that identifies if the container object has been updated based on the dirty fields of the
 * form.
 *
 * @param dirtyFields The dirty fields of the form.
 */
const checkIfContainerObjectHasBeenUpdated = (dirtyFields: Partial<Readonly<{ [x: string]: any }>>) => {
  const containerFields = [
    EditContainerGroupField.COMMAND,
    EditContainerGroupField.COMMAND_ARGUMENTS,
    EditContainerGroupField.DISK_SPACE,
    EditContainerGroupField.ENVIRONMENT_VARIABLES,
    EditContainerGroupField.EXTERNAL_LOGGING_SERVICE,
    EditContainerGroupField.GPU,
    EditContainerGroupField.IMAGE_SOURCE,
    EditContainerGroupField.MEMORY,
    EditContainerGroupField.VCPUS,
    EditContainerGroupField.AXIOM_API_KEY,
    EditContainerGroupField.AXIOM_DATASET_NAME,
    EditContainerGroupField.AXIOM_HOST,
    EditContainerGroupField.DATADOG_API,
    EditContainerGroupField.DATADOG_HOST,
    EditContainerGroupField.DATADOG_TAGS,
    EditContainerGroupField.HTTP_COMPRESSION,
    EditContainerGroupField.HTTP_FORMAT,
    EditContainerGroupField.HTTP_HEADERS,
    EditContainerGroupField.HTTP_HOST,
    EditContainerGroupField.HTTP_PASSWORD,
    EditContainerGroupField.HTTP_PATH,
    EditContainerGroupField.HTTP_PORT,
    EditContainerGroupField.HTTP_USER,
    EditContainerGroupField.PRIORITY,
    EditContainerGroupField.NEW_RELIC_HOST,
    EditContainerGroupField.NEW_RELIC_INGESTION_KEY,
    EditContainerGroupField.SPLUNK_HOST,
    EditContainerGroupField.SPLUNK_TOKEN,
    EditContainerGroupField.TCP_HOST,
    EditContainerGroupField.TCP_PORT,
  ]

  return containerFields.some((field) => dirtyFields[field])
}

/**
 * Returns a boolean value that identifies if the logging object has been updated based on the dirty fields of the form.
 *
 * @param dirtyFields The dirty fields of the form.
 */
const checkIfExternalLoggingHasBeenUpdated = (dirtyFields: Partial<Readonly<{ [x: string]: any }>>) => {
  return externalLoggingFieldsList.some((field) => dirtyFields[field])
}

/**
 * Returns a boolean value that identifies if the startup probe has been updated based on the dirty fields of the form.
 *
 * @param dirtyFields The dirty fields of the form.
 */
const checkIfStartupProbeHasBeenUpdated = (dirtyFields: Partial<Readonly<{ [x: string]: any }>>) => {
  return editStartupProbeFieldsList.some((field) => dirtyFields[field])
}

/**
 * Returns a boolean value that identifies if the liveness probe has been updated based on the dirty fields of the form.
 *
 * @param dirtyFields The dirty fields of the form.
 */
const checkIfLivenessProbeHasBeenUpdated = (dirtyFields: Partial<Readonly<{ [x: string]: any }>>) => {
  return editLivenessProbeFieldsList.some((field) => dirtyFields[field])
}

/**
 * Returns a boolean value that identifies if the readiness probe has been updated based on the dirty fields of the
 * form.
 *
 * @param dirtyFields The dirty fields of the form.
 */
const checkIfReadinessProbeHasBeenUpdated = (dirtyFields: Partial<Readonly<{ [x: string]: any }>>) => {
  return editReadinessProbeFieldsList.some((field) => dirtyFields[field])
}

/**
 * Returns a boolean value that identifies if the autoscaling object has been updated based on the dirty fields of the
 * form.
 *
 * @param dirtyFields The dirty fields of the form.
 */
const checkIfAutoscalingHasBeenUpdated = (dirtyFields: Partial<Readonly<{ [x: string]: any }>>) => {
  return editAutoscalingFieldsList.some((field) => dirtyFields[field])
}

/**
 * The submit handler for the Create Container Group form that configures the object shape to be passed along to the
 * API.
 *
 * @param fieldValues The create container group form field values.
 * @param onSubmit The submit callback that when evoked creates the container group.
 */
export const handleSubmitEditContainerGroup = (
  fieldValues: EditContainerGroupValues,
  dirtyFields: Partial<Readonly<{ [x: string]: any }>>,
  onSubmit: (values: UpdateContainerGroup) => void,
) => {
  const {
    autoscalingDesiredQueueLength,
    autoscalingEnabled,
    autoscalingMaximumDownscalePerMinute,
    autoscalingMaximumReplicas,
    autoscalingMaximumUpscalePerMinute,
    autoscalingMinimumReplicas,
    autoscalingPeriod,
    awsElasticAccessKeyId,
    awsElasticSecretAccessKey,
    axiomApiKey,
    axiomDatasetName,
    axiomHost,
    azurePassword,
    azureUsername,
    command,
    commandArguments,
    containerGatewayEnabled,
    containerGatewayPort,
    datadogApi,
    datadogHost,
    datadogTags,
    diskSpace,
    displayName,
    dockerHubPersonalAccessToken,
    dockerHubUsername,
    environmentVariables,
    externalLoggingService,
    gitHubPersonalAccessToken,
    gitHubUsername,
    googleArtifactJsonKey,
    googleContainerJsonKey,
    gpu,
    httpCompression,
    httpFormat,
    httpHeaders,
    httpHost,
    httpPassword,
    httpPath,
    httpPort,
    httpUser,
    imageSource,
    imageType,
    livenessProbeCommand,
    livenessProbeCommandArguments,
    livenessProbeEnabled,
    livenessProbeFailureThreshold,
    livenessProbeHeaders,
    livenessProbeInitialDelaySeconds,
    livenessProbePath,
    livenessProbePeriodSeconds,
    livenessProbePort,
    livenessProbeProtocol,
    livenessProbeService,
    livenessProbeSuccessThreshold,
    livenessProbeTimeoutSeconds,
    memory,
    newRelicHost,
    newRelicIngestionKey,
    priority,
    privateRegistryProvider,
    quayPassword,
    quayUsername,
    readinessProbeCommand,
    readinessProbeCommandArguments,
    readinessProbeEnabled,
    readinessProbeFailureThreshold,
    readinessProbeHeaders,
    readinessProbeInitialDelaySeconds,
    readinessProbePath,
    readinessProbePeriodSeconds,
    readinessProbePort,
    readinessProbeProtocol,
    readinessProbeService,
    readinessProbeSuccessThreshold,
    readinessProbeTimeoutSeconds,
    replicaCount,
    selfHostedPassword,
    selfHostedUsername,
    splunkHost,
    splunkToken,
    startupProbeCommand,
    startupProbeCommandArguments,
    startupProbeEnabled,
    startupProbeFailureThreshold,
    startupProbeHeaders,
    startupProbeInitialDelaySeconds,
    startupProbePath,
    startupProbePeriodSeconds,
    startupProbePort,
    startupProbeProtocol,
    startupProbeService,
    startupProbeSuccessThreshold,
    startupProbeTimeoutSeconds,
    tcpHost,
    tcpPort,
    vcpus,
  } = fieldValues

  let constructedCommand = null
  if (command) {
    const args = commandArguments?.map((arg) => arg.argument || '')
    constructedCommand = args ? flattenDeep([command, ...args]) : [command]
  }

  let envVariables: { [key: string]: string } | undefined = {}
  if (environmentVariables !== undefined && environmentVariables.length > 0) {
    envVariables = environmentVariables.reduce((accumulator, item) => {
      return { ...accumulator, [item.key]: item.value }
    }, {})
  }

  let logging: ContainerLogging | null = null
  if (externalLoggingService) {
    if (externalLoggingService === ExternalLoggingService.AXIOM && axiomApiKey && axiomDatasetName && axiomHost) {
      logging = {
        axiom: {
          apiToken: axiomApiKey,
          dataset: axiomDatasetName,
          host: axiomHost,
        },
      }
    }

    if (externalLoggingService === ExternalLoggingService.DATADOG && datadogApi && datadogHost) {
      logging = {
        datadog: {
          apiKey: datadogApi,
          host: datadogHost,
          tags: datadogTags,
        },
      }
    }

    if (
      externalLoggingService === ExternalLoggingService.HTTP &&
      httpCompression &&
      httpFormat &&
      httpPort &&
      httpHost
    ) {
      logging = {
        http: {
          compression: httpCompression,
          format: httpFormat,
          headers: httpHeaders && httpHeaders.length > 0 ? httpHeaders : null,
          host: httpHost,
          password: httpPassword ?? null,
          path: httpPath ?? null,
          port: httpPort,
          user: httpUser ?? null,
        },
      }
    }

    if (externalLoggingService === ExternalLoggingService.NEW_RELIC && newRelicHost && newRelicIngestionKey) {
      logging = {
        newRelic: {
          host: newRelicHost,
          ingestionKey: newRelicIngestionKey,
        },
      }
    }

    if (externalLoggingService === ExternalLoggingService.SPLUNK && splunkHost && splunkToken) {
      logging = {
        splunk: {
          host: splunkHost,
          token: splunkToken,
        },
      }
    }

    if (externalLoggingService === ExternalLoggingService.TCP && tcpHost && tcpPort) {
      logging = {
        tcp: {
          host: tcpHost,
          port: tcpPort,
        },
      }
    }
  }

  const livenessProbe = configureHealthProbeValues({
    command: livenessProbeCommand,
    commandArgs: livenessProbeCommandArguments,
    enabled: livenessProbeEnabled,
    failureThreshold: livenessProbeFailureThreshold,
    headers: livenessProbeHeaders,
    initialDelaySeconds: livenessProbeInitialDelaySeconds,
    periodSeconds: livenessProbePeriodSeconds,
    path: livenessProbePath,
    port: livenessProbePort,
    protocol: livenessProbeProtocol,
    service: livenessProbeService,
    successThreshold: livenessProbeSuccessThreshold,
    timeoutSeconds: livenessProbeTimeoutSeconds,
  })

  const readinessProbe = configureHealthProbeValues({
    command: readinessProbeCommand,
    commandArgs: readinessProbeCommandArguments,
    enabled: readinessProbeEnabled,
    failureThreshold: readinessProbeFailureThreshold,
    headers: readinessProbeHeaders,
    initialDelaySeconds: readinessProbeInitialDelaySeconds,
    path: readinessProbePath,
    periodSeconds: readinessProbePeriodSeconds,
    port: readinessProbePort,
    protocol: readinessProbeProtocol,
    service: readinessProbeService,
    successThreshold: readinessProbeSuccessThreshold,
    timeoutSeconds: readinessProbeTimeoutSeconds,
  })

  const startupProbe = configureHealthProbeValues({
    command: startupProbeCommand,
    commandArgs: startupProbeCommandArguments,
    enabled: startupProbeEnabled,
    failureThreshold: startupProbeFailureThreshold,
    headers: startupProbeHeaders,
    initialDelaySeconds: startupProbeInitialDelaySeconds,
    path: startupProbePath,
    periodSeconds: startupProbePeriodSeconds,
    port: startupProbePort,
    protocol: startupProbeProtocol,
    service: startupProbeService,
    successThreshold: startupProbeSuccessThreshold,
    timeoutSeconds: startupProbeTimeoutSeconds,
  })

  const updatedContainerGroup: UpdateContainerGroup = {}

  if (dirtyFields[EditContainerGroupField.DISPLAY_NAME]) {
    updatedContainerGroup.displayName = displayName
  }

  if (dirtyFields[EditContainerGroupField.REPLICA_COUNT]) {
    updatedContainerGroup.replicas = replicaCount
  }

  // Updated Container Object
  if (checkIfContainerObjectHasBeenUpdated(dirtyFields)) {
    const updatedContainer: Partial<CreateContainer> = {}
    if (dirtyFields[EditContainerGroupField.COMMAND] || dirtyFields[EditContainerGroupField.COMMAND_ARGUMENTS]) {
      updatedContainer.command = constructedCommand
    }

    if (dirtyFields[EditContainerGroupField.ENVIRONMENT_VARIABLES]) {
      updatedContainer.environmentVariables = envVariables
    }

    if (dirtyFields[EditContainerGroupField.IMAGE_SOURCE]) {
      updatedContainer.image = imageSource
    }

    if (checkIfExternalLoggingHasBeenUpdated(dirtyFields)) {
      updatedContainer.logging = logging
    }

    if (dirtyFields[EditContainerGroupField.GPU]) {
      // @ts-ignore - this will not error out when the types have been updated
      updatedContainer.resources = {
        ...updatedContainer.resources,
        gpuClasses: gpu,
      }
    }

    if (dirtyFields[EditContainerGroupField.MEMORY]) {
      // @ts-ignore - this will not error out when the types have been updated
      updatedContainer.resources = {
        ...updatedContainer.resources,
        memory,
      }
    }

    if (dirtyFields[EditContainerGroupField.PRIORITY]) {
      updatedContainer.priority = priority
    }

    if (dirtyFields[EditContainerGroupField.DISK_SPACE]) {
      // @ts-ignore - this will not error out when the types have been updated
      updatedContainer.resources = {
        ...updatedContainer.resources,
        storageAmount: diskSpace,
      }
    }

    if (dirtyFields[EditContainerGroupField.VCPUS]) {
      // @ts-ignore - this will not error out when the types have been updated
      updatedContainer.resources = {
        ...updatedContainer.resources,
        cpu: vcpus,
      }
    }

    updatedContainerGroup.container = updatedContainer
  }

  // Startup Probe
  if (checkIfStartupProbeHasBeenUpdated(dirtyFields)) {
    updatedContainerGroup.startupProbe = startupProbe
  }

  // Liveness Probe
  if (checkIfLivenessProbeHasBeenUpdated(dirtyFields)) {
    updatedContainerGroup.livenessProbe = livenessProbe
  }

  // Readiness Probe
  if (checkIfReadinessProbeHasBeenUpdated(dirtyFields)) {
    updatedContainerGroup.readinessProbe = readinessProbe
  }

  // Container Gateway
  if (containerGatewayEnabled && dirtyFields[EditContainerGroupField.CONTAINER_GATEWAY_PORT]) {
    updatedContainerGroup.networking = {
      port: containerGatewayPort,
    }
  }

  if (checkIfAutoscalingHasBeenUpdated(dirtyFields)) {
    if (
      autoscalingEnabled &&
      autoscalingMinimumReplicas &&
      autoscalingMaximumReplicas &&
      autoscalingDesiredQueueLength
    ) {
      const queueAutoscaler: QueueAutoscaler = {
        desiredQueueLength: autoscalingDesiredQueueLength,
        maxDownscalePerMinute: autoscalingMaximumDownscalePerMinute,
        maxReplicas: autoscalingMaximumReplicas,
        maxUpscalePerMinute: autoscalingMaximumUpscalePerMinute,
        minReplicas: autoscalingMinimumReplicas,
        pollingPeriod: autoscalingPeriod,
      }
      updatedContainerGroup.queueAutoscaler = queueAutoscaler
    }
    if (!autoscalingEnabled) {
      updatedContainerGroup.queueAutoscaler = null
    }
  }

  // Private Registry Image Source
  if (dirtyFields[EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER]) {
    if (privateRegistryProvider === PrivateRegistryProvider.GOOGLE_ARTIFACT_REGISTRY && googleArtifactJsonKey) {
      const file = googleArtifactJsonKey[0]
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const GcpGcrRegistryAuthentication: CreateContainerRegistryAuthentication = {
          gcpGar: {
            serviceKey: reader.result as string,
          },
        }

        if (updatedContainerGroup.container) {
          updatedContainerGroup.container.registryAuthentication = GcpGcrRegistryAuthentication
        }
        return onSubmit(updatedContainerGroup)
      })

      reader.addEventListener('error', () => {
        // TODO: Get requirements on how we would like to show this error to the end user
        console.error('Error reading file')
      })
      if (file) {
        reader.readAsText(file)
      }
    } else if (
      privateRegistryProvider === PrivateRegistryProvider.GOOGLE_CONTAINER_REGISTRY &&
      googleContainerJsonKey
    ) {
      const file = googleContainerJsonKey[0]
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const GcpGcrRegistryAuthentication: CreateContainerRegistryAuthentication = {
          gcpGcr: {
            serviceKey: reader.result as string,
          },
        }

        if (updatedContainerGroup.container) {
          updatedContainerGroup.container.registryAuthentication = GcpGcrRegistryAuthentication
        }
        return onSubmit(updatedContainerGroup)
      })

      reader.addEventListener('error', () => {
        // TODO: Get requirements on how we would like to show this error to the end user
        console.error('Error reading file')
      })
      if (file) {
        reader.readAsText(file)
      }
    } else {
      if (updatedContainerGroup.container) {
        updatedContainerGroup.container.registryAuthentication = configureRegistryAuthentication(
          imageType,
          privateRegistryProvider,
          dockerHubUsername,
          dockerHubPersonalAccessToken,
          gitHubUsername,
          gitHubPersonalAccessToken,
          quayUsername,
          quayPassword,
          awsElasticAccessKeyId,
          awsElasticSecretAccessKey,
          selfHostedUsername,
          selfHostedPassword,
          azureUsername,
          azurePassword,
        )
      }
    }
    onSubmit(updatedContainerGroup)
  } else {
    onSubmit(updatedContainerGroup)
  }
}

interface ProbeValues {
  enabled?: boolean
  command?: string
  commandArgs?: { argument: string }[]
  failureThreshold?: number
  headers?: HttpHeadersInner[]
  initialDelaySeconds?: number
  path?: string
  periodSeconds?: number
  port?: number
  protocol?: string
  service?: string
  successThreshold?: number
  timeoutSeconds?: number
}

/**
 * A function that handles taking the values from any health probe from and configuring them to be passed along to the
 * API request for creating a container group.
 *
 * @param values The health probe form values.
 */
const configureHealthProbeValues = (
  values: ProbeValues,
): ContainerGroupLivenessProbe | ContainerGroupReadinessProbe | ContainerGroupStartupProbe | null => {
  const {
    command,
    commandArgs,
    enabled,
    failureThreshold,
    headers,
    initialDelaySeconds,
    path,
    periodSeconds,
    port,
    protocol,
    service,
    successThreshold,
    timeoutSeconds,
  } = values
  let healthProbe: ContainerGroupLivenessProbe | ContainerGroupReadinessProbe | ContainerGroupStartupProbe | null = null

  if (
    enabled &&
    failureThreshold !== undefined &&
    initialDelaySeconds !== undefined &&
    periodSeconds !== undefined &&
    protocol &&
    successThreshold !== undefined &&
    timeoutSeconds !== undefined
  ) {
    const commonValues = {
      failureThreshold,
      initialDelaySeconds,
      protocol,
      periodSeconds,
      successThreshold,
      timeoutSeconds,
    }

    // Health Probe with Exec Protocol
    if (protocol === ProtocolOptions.EXEC && command) {
      const commandArguments = commandArgs?.map((arg) => arg.argument)
      const constructedCommand = commandArguments ? flattenDeep([command, ...commandArguments]) : [command]
      healthProbe = {
        exec: {
          command: constructedCommand,
        },
        ...commonValues,
      }
    }

    // Health Probe with gRPC Protocol
    if (protocol === ProtocolOptions.GRPC && port) {
      healthProbe = {
        grpc: {
          port,
          service: service || '',
        },
        ...commonValues,
      }
    }

    // Health Probe with HTTP Protocol
    if (protocol === ProtocolOptions.HTTP1X && port && path) {
      healthProbe = {
        http: {
          headers,
          path,
          port,
          scheme: 'http',
        },
        ...commonValues,
      }
    }

    // Health Probe with TCP Protocol
    if (protocol === ProtocolOptions.TCP && port) {
      healthProbe = {
        tcp: {
          port,
        },
        ...commonValues,
      }
    }
  }

  return healthProbe
}

/**
 * A callback that identifies the first field with a validation error, if any error exists, and returns the section id
 * where that field is placed for the `CreateContainerGroup` form.
 *
 * @param onGetFieldState The `react-hook-form` function that returns the state of a field.
 */
export const getSectionIdWithFirstValidationError = (
  onGetFieldState: UseFormGetFieldState<FieldValues>,
): EditContainerGroupFormSectionIdAttributes | undefined => {
  const fields = Object.values(EditContainerGroupField)

  for (const field of fields) {
    const fieldState = onGetFieldState(field)
    if (fieldState?.invalid) {
      return getFieldSectionId(field)
    }
  }

  return undefined
}

/**
 * Based on the field that is passed in, this function will return the appropriate section id for the
 * `CreateContainerGroup` form.
 *
 * @param field The field.
 */
const getFieldSectionId = (field: string): EditContainerGroupFormSectionIdAttributes => {
  switch (field) {
    case EditContainerGroupField.DISPLAY_NAME:
      return EditContainerGroupFormSectionIdAttributes.CONTAINER_GROUP_DISPLAY_NAME

    case EditContainerGroupField.IMAGE_SOURCE:
    case EditContainerGroupField.IMAGE_TYPE:
    case EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER:
    case EditContainerGroupField.DOCKER_HUB_USERNAME:
    case EditContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN:
    case EditContainerGroupField.GIT_HUB_USERNAME:
    case EditContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN:
    case EditContainerGroupField.QUAY_USERNAME:
    case EditContainerGroupField.QUAY_PASSWORD:
    case EditContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID:
    case EditContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY:
    case EditContainerGroupField.SELF_HOSTED_USERNAME:
    case EditContainerGroupField.SELF_HOSTED_PASSWORD:
    case EditContainerGroupField.AZURE_USERNAME:
    case EditContainerGroupField.AZURE_PASSWORD:
    case EditContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY:
    case EditContainerGroupField.GOOGLE_CONTAINER_JSON_KEY:
      return EditContainerGroupFormSectionIdAttributes.IMAGE_SOURCE

    case EditContainerGroupField.REPLICA_COUNT:
      return EditContainerGroupFormSectionIdAttributes.REPLICA_COUNT

    case EditContainerGroupField.VCPUS:
      return EditContainerGroupFormSectionIdAttributes.VCPU

    case EditContainerGroupField.MEMORY:
      return EditContainerGroupFormSectionIdAttributes.MEMORY

    case EditContainerGroupField.GPU:
      return EditContainerGroupFormSectionIdAttributes.GPU

    case EditContainerGroupField.DISK_SPACE:
      return EditContainerGroupFormSectionIdAttributes.DISK_SPACE

    case EditContainerGroupField.STARTUP_PROBE_COMMAND:
    case EditContainerGroupField.STARTUP_PROBE_COMMAND_ARGUMENTS:
    case EditContainerGroupField.STARTUP_PROBE_ENABLED:
    case EditContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD:
    case EditContainerGroupField.STARTUP_PROBE_HEADERS:
    case EditContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS:
    case EditContainerGroupField.STARTUP_PROBE_PATH:
    case EditContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS:
    case EditContainerGroupField.STARTUP_PROBE_PORT:
    case EditContainerGroupField.STARTUP_PROBE_PROTOCOL:
    case EditContainerGroupField.STARTUP_PROBE_SERVICE:
    case EditContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD:
    case EditContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS:
      return EditContainerGroupFormSectionIdAttributes.STARTUP_PROBE

    case EditContainerGroupField.LIVENESS_PROBE_COMMAND:
    case EditContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS:
    case EditContainerGroupField.LIVENESS_PROBE_ENABLED:
    case EditContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD:
    case EditContainerGroupField.LIVENESS_PROBE_HEADERS:
    case EditContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS:
    case EditContainerGroupField.LIVENESS_PROBE_PATH:
    case EditContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS:
    case EditContainerGroupField.LIVENESS_PROBE_PORT:
    case EditContainerGroupField.LIVENESS_PROBE_PROTOCOL:
    case EditContainerGroupField.LIVENESS_PROBE_SERVICE:
    case EditContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD:
    case EditContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS:
      return EditContainerGroupFormSectionIdAttributes.LIVENESS_PROBE

    case EditContainerGroupField.READINESS_PROBE_COMMAND:
    case EditContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS:
    case EditContainerGroupField.READINESS_PROBE_ENABLED:
    case EditContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD:
    case EditContainerGroupField.READINESS_PROBE_HEADERS:
    case EditContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS:
    case EditContainerGroupField.READINESS_PROBE_PATH:
    case EditContainerGroupField.READINESS_PROBE_PERIOD_SECONDS:
    case EditContainerGroupField.READINESS_PROBE_PORT:
    case EditContainerGroupField.READINESS_PROBE_PROTOCOL:
    case EditContainerGroupField.READINESS_PROBE_SERVICE:
    case EditContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD:
    case EditContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS:
      return EditContainerGroupFormSectionIdAttributes.READINESS_PROBE

    case EditContainerGroupField.COMMAND:
    case EditContainerGroupField.COMMAND_ARGUMENTS:
      return EditContainerGroupFormSectionIdAttributes.COMMAND

    case EditContainerGroupField.CONTAINER_GATEWAY_ENABLED:
    case EditContainerGroupField.CONTAINER_GATEWAY_PORT:
    case EditContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION:
      return EditContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY

    case EditContainerGroupField.JOB_QUEUE:
    case EditContainerGroupField.JOB_QUEUE_PATH:
    case EditContainerGroupField.JOB_QUEUE_PORT:
      return EditContainerGroupFormSectionIdAttributes.JOB_QUEUE

    case EditContainerGroupField.EXTERNAL_LOGGING_SERVICE:
    case EditContainerGroupField.AXIOM_API_KEY:
    case EditContainerGroupField.AXIOM_DATASET_NAME:
    case EditContainerGroupField.AXIOM_HOST:
    case EditContainerGroupField.DATADOG_API:
    case EditContainerGroupField.DATADOG_HOST:
    case EditContainerGroupField.DATADOG_TAGS:
    case EditContainerGroupField.HTTP_COMPRESSION:
    case EditContainerGroupField.HTTP_FORMAT:
    case EditContainerGroupField.HTTP_HEADERS:
    case EditContainerGroupField.HTTP_HOST:
    case EditContainerGroupField.HTTP_PASSWORD:
    case EditContainerGroupField.HTTP_PATH:
    case EditContainerGroupField.HTTP_PORT:
    case EditContainerGroupField.HTTP_USER:
    case EditContainerGroupField.NEW_RELIC_HOST:
    case EditContainerGroupField.NEW_RELIC_INGESTION_KEY:
    case EditContainerGroupField.SPLUNK_HOST:
    case EditContainerGroupField.SPLUNK_TOKEN:
    case EditContainerGroupField.TCP_HOST:
    case EditContainerGroupField.TCP_PORT:
      return EditContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE

    case EditContainerGroupField.ENVIRONMENT_VARIABLES:
      return EditContainerGroupFormSectionIdAttributes.ENVIRONMENT_VARIABLES

    default:
      return EditContainerGroupFormSectionIdAttributes.CONTAINER_GROUP_DISPLAY_NAME
  }
}
