import { defineMessages } from 'react-intl'

export const LeftNavigationBarMessages = defineMessages({
  accountSettingsNavigationLabel: {
    defaultMessage: 'Account Settings',
    id: 'rAieBEdA',
    description: 'The label for the Account Settings Page link',
  },
  apiAccessNavigationLabel: {
    defaultMessage: 'API Access',
    id: 'SknZsGYr',
    description: 'The label for the API Access Page link',
  },
  billingAndUsageNavigationLabel: {
    defaultMessage: 'Billing & Usage',
    id: 'VN+ZSja0',
    description: 'The label for the Billing & Usage Page link',
  },
  contactSupportButtonLabel: {
    defaultMessage: 'Contact Support',
    id: 'booiP0hI',
    description: 'The label for the contact support button',
  },
  containerGroupsNavigationLabel: {
    defaultMessage: 'Container Groups',
    id: 'akfnlek3',
    description: 'The label the Container Groups page link',
  },
  createProjectLabel: {
    defaultMessage: 'Create a Project',
    id: 'Ht+eNpDr',
    description: 'The label for creating a project',
  },
  deleteProjectLabel: {
    defaultMessage: 'Delete',
    id: 'ammQC0sm',
    description: 'The label for delete a project',
  },
  deployContainerGroupLabel: {
    defaultMessage: 'Deploy New Container Group',
    id: 'UDgPP3ma',
    description: 'The button label for viewing the Deploy New Container Group page.',
  },
  documentationNavigationLabel: {
    defaultMessage: 'Documentation',
    id: 'joXKLg4+',
    description: 'The label for the Documentation Page link',
  },
  editProjectLabel: {
    defaultMessage: 'Edit',
    id: 'N4E+0nCk',
    description: 'The label for editing a project',
  },
  helpScoutWidgetFailedViaContactSupportMixpanelEventLabeL: {
    defaultMessage: 'Help Scout Widget Failed to Open Via Contact Support',
    id: 'Pm3zX6Tv',
    description: 'The label for the HelpScout Widget Failed to Open Mixpanel Event',
  },
  inferenceEndpointsNavigationLabel: {
    defaultMessage: 'Inference Endpoints',
    id: '1sf+Jwds',
    description: 'The label the Inference Endpoints page link',
  },
  inviteCodeCancel: {
    defaultMessage: 'Cancel',
    id: '2V9C4tVT',
    description: 'The label for the cancel button in the invite code modal',
  },
  inviteCodeTitle: {
    defaultMessage: 'Invite Code',
    id: 'lwaNT89q',
    description: 'The title of the invite code modal',
  },
  jobQueuesNavigationLabel: {
    defaultMessage: 'Job Queues',
    id: '+I8BhCBe',
    description: 'The label the Job Queues page link',
  },
  organizationsNavigationLabel: {
    defaultMessage: 'Your Organizations',
    id: 'N1lLTXuu',
    description: 'The label for the Organizations Page link',
  },
  selectProjectDropdownLabel: {
    defaultMessage: 'Select Project',
    id: 'pnBZjP2e',
    description: 'The label the dropdown for selecting a project',
  },
  teamNavigationLabel: {
    defaultMessage: 'Team',
    id: 'Nh/mDIfH',
    description: 'The label for the Team Page link',
  },
  viewInferenceEndpointsMarketplaceLabel: {
    defaultMessage: 'View Inference Endpoints',
    id: 'wh58YrRC',
    description: 'The button label for viewing the Inference Endpoints Marketplace page.',
  },
  webhookSecretKeyNavigationLabel: {
    defaultMessage: 'Webhook Secret Key',
    id: 'EyFjd910',
    description: 'The label for the Webhook Secret Key Page link',
  },
})
