import { defineMessages } from 'react-intl'

export const CreateInferenceEndpointJobInvalidMetadataErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your inference endpoint job because the metadata you entered were invalid. Please {contact_support} for further assistance.',
    id: 'Iz/Qg1AT',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an inference endpoint job because of invalid meta data.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'RqJIefYN',
    description:
      'The text shown for the contact support link in the create inference endpoint job error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating inference endpoint job',
    id: 'rhj0iUtt',
    description:
      'The toast notification title shown when we encounter an error on their request to create an inference endpoint job because of invalid meta data.',
  },
})
