import { defineMessages } from 'react-intl'

export const CreateInferenceEndpointJobInvalidInputErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your inference endpoint job because the inputs you entered were invalid. Please {contact_support} for further assistance.',
    id: 'SrgQikJT',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an inference endpoint job because of invalid inputs.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'RqJIefYN',
    description:
      'The text shown for the contact support link in the create inference endpoint job error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating inference endpoint job',
    id: 'oxrPb8AX',
    description:
      'The toast notification title shown when we encounter an error on their request to create an inference endpoint job because of invalid inputs.',
  },
})
