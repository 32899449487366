import type { AppState } from '../../store'
import { inferenceEndpointsAdapter } from './inferenceEndpointsSlice'

const inferenceEndpointsSelectors = inferenceEndpointsAdapter.getSelectors()

export const selectInferenceEndpoints = (state: AppState, organizationName: string) =>
  inferenceEndpointsSelectors.selectById(state.inferenceEndpoints, organizationName)?.inferenceEndpoints || []

export const selectInferenceEndpoint = (state: AppState, organizationName: string, name: string) => {
  const inferenceEndpoints = state.inferenceEndpoints.entities[organizationName]?.inferenceEndpoints ?? []

  if (inferenceEndpoints.length > 0) {
    return inferenceEndpoints.find((inferenceEndpoint) => inferenceEndpoint.name === name)
  }

  return undefined
}

export const selectIsInferenceEndpointBillingInformationMissingModalShowing = (
  state: AppState,
  organizationName: string,
) =>
  inferenceEndpointsSelectors.selectById(state.inferenceEndpoints, organizationName)
    ?.isBillingInformationMissingModalShowing
