import { combineReducers, configureStore } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { mockCreateOrganizationWizardSlice } from './mockCreateOrganizationWizard/mockCreateOrganizationWizardSlice'
import { mockFeatureFlagsSlice } from './mockFeatureFlags/mockFeatureFlagsSlice'
import { mockNavigationBarSlice } from './mockNavigationBar/mockNavigationBarSlice'

const reducer = combineReducers({
  createOrganizationWizard: mockCreateOrganizationWizardSlice.reducer,
  featureFlags: mockFeatureFlagsSlice.reducer,
  navigationBar: mockNavigationBarSlice.reducer,
})

export const mockStore = configureStore({
  reducer,
  preloadedState: {},
})

export const useMockAppDispatch = () => useDispatch<MockAppDispatch>()

export const useMockAppSelector: TypedUseSelectorHook<MockAppState> = useSelector

export type MockAppState = ReturnType<typeof reducer>
export type MockAppDispatch = (typeof mockStore)['dispatch']
