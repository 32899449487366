import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { useFixedForm } from '../../../../hooks'
import { CreateOrganizationMainContentMessages } from '../../messages'
import { useCreateOrganizationFormValidation } from './useCreateOrganizationFormValidation'

export interface CreateOrganizationValues {
  /** The organization name. */
  organizationName: string
  /** The project name. */
  projectName: string
}

interface CreateOrganizationPageMainContentProps {
  /** The flag indicating that the request to create an organization is pending. */
  isSubmitPending: boolean
  /** A callback that when executed creates an organization. */
  onCreateOrganization: (organizationName: string, projectName: string) => void
}

export const CreateOrganizationPageMainContent: FunctionComponent<CreateOrganizationPageMainContentProps> = ({
  isSubmitPending,
  onCreateOrganization,
}) => {
  const intl = useIntl()
  const createOrganizationValidationScheme = useCreateOrganizationFormValidation()
  const { control, handleSubmit } = useFixedForm<CreateOrganizationValues>({
    resolver: yupResolver(createOrganizationValidationScheme),
    onSubmit: ({ organizationName, projectName }) => onCreateOrganization(organizationName, projectName),
  })

  return (
    <div className="w-full max-w-md">
      <div className="mb-4">
        <Link url="/organizations">
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(CreateOrganizationMainContentMessages.returnToOrganizationsLinkText)}
        </Link>
      </div>
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(CreateOrganizationMainContentMessages.title)}</h1>
      <p className="mb-10 text-base">
        {intl.formatMessage(CreateOrganizationMainContentMessages.description, {
          permanent_name: (
            <span className="font-bold">
              {intl.formatMessage(CreateOrganizationMainContentMessages.descriptionPermanentNameText)}
            </span>
          ),
        })}
      </p>

      <form onSubmit={handleSubmit}>
        <h2 className="mb-2 text-xl font-bold">
          {intl.formatMessage(CreateOrganizationMainContentMessages.organizationDetailsHeader)}
        </h2>
        <p className="mb-8 text-base">
          {intl.formatMessage(CreateOrganizationMainContentMessages.organizationDetailsDescription)}
        </p>
        <div className="pb-6">
          <Controller
            name="organizationName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                {...fieldState}
                helperText={intl.formatMessage(CreateOrganizationMainContentMessages.organizationNameHelperText)}
                isFullWidth
                label={intl.formatMessage(CreateOrganizationMainContentMessages.organizationNameFieldLabel)}
                placeholder={intl.formatMessage(CreateOrganizationMainContentMessages.organizationNameFieldPlaceholder)}
              />
            )}
          />
        </div>

        <h2 className="mb-2 text-xl font-bold">
          {intl.formatMessage(CreateOrganizationMainContentMessages.projectDetailsHeader)}
        </h2>
        <p className="mb-8 text-base">
          {intl.formatMessage(CreateOrganizationMainContentMessages.projectDetailsDescription)}
        </p>
        <Controller
          name="projectName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              helperText={intl.formatMessage(CreateOrganizationMainContentMessages.projectNameHelperText)}
              placeholder={intl.formatMessage(CreateOrganizationMainContentMessages.projectNameFieldPlaceholder)}
              label={intl.formatMessage(CreateOrganizationMainContentMessages.projectNameFieldLabel)}
              isFullWidth
            />
          )}
        />

        <Button type="submit" isLoading={isSubmitPending} isDisabled={isSubmitPending} isFullWidth>
          {intl.formatMessage(CreateOrganizationMainContentMessages.createOrganizationButtonLabel)}
        </Button>
      </form>
    </div>
  )
}
