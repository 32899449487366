import { defineMessages } from 'react-intl'

export const CreateInferenceEndpointJobGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'An error occurred while handling your inference endpoint job request. Please retry, and if the issue continues, {contact_support} for further assistance.',
    id: 'NOVv1aex',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an inference endpoint job.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'RqJIefYN',
    description:
      'The text shown for the contact support link in the create inference endpoint job error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating inference endpoint job',
    id: 'xPN7GhqD',
    description:
      'The toast notification title shown when we encounter an error on their request to create an inference endpoint job.',
  },
})
