import { useState, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import {
  getContainerGroupsPagePath,
  getInferenceEndpointsMarketplacePagePath,
  getJobQueuesPagePath,
  getOrganizationTeamPagePath,
  getOrganizationWebhookSecretKeyPagePath,
} from '../../../routes/routes-utils'
import { contactSupportViaHelpScoutAsks } from '../../../utils/helpScout'
import { HelpScoutBeaconFailedToLoadModal } from '../../../utils/helpScout/components'
import { MenuItem } from '../../block/MenuItem'
import { LeftNavigationBarMessages } from '../messages'

interface MenuItemsProps {
  organizationName?: string
  projectName?: string
}

export const StorybookMenuItems: FunctionComponent<MenuItemsProps> = ({ organizationName, projectName }) => {
  const intl = useIntl()
  const [isHelpScoutBeaconFailedToLoadModalOpen, setIsHelpScoutBeaconFailedToLoadModalOpen] = useState(false)

  const handleContactSupportClick = () => {
    contactSupportViaHelpScoutAsks(setIsHelpScoutBeaconFailedToLoadModalOpen)
  }

  return (
    <>
      <ul>
        {organizationName === undefined && projectName === undefined && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-layer-group"
                label={intl.formatMessage(LeftNavigationBarMessages.organizationsNavigationLabel)}
                path="/organizations"
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-cog"
                label={intl.formatMessage(LeftNavigationBarMessages.accountSettingsNavigationLabel)}
                path="/account"
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-code"
                label={intl.formatMessage(LeftNavigationBarMessages.apiAccessNavigationLabel)}
                path="/api-key"
                variant="left-nav"
              />
            </li>
          </>
        )}

        {organizationName && projectName && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-code"
                label={intl.formatMessage(LeftNavigationBarMessages.inferenceEndpointsNavigationLabel)}
                path={getInferenceEndpointsMarketplacePagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-boxes"
                label={intl.formatMessage(LeftNavigationBarMessages.containerGroupsNavigationLabel)}
                path={getContainerGroupsPagePath(organizationName, projectName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-window-restore"
                isFeaturePreview
                label={intl.formatMessage(LeftNavigationBarMessages.jobQueuesNavigationLabel)}
                path={getJobQueuesPagePath(organizationName, projectName)}
                variant="left-nav"
              />
            </li>
          </>
        )}

        {organizationName && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-key"
                label={intl.formatMessage(LeftNavigationBarMessages.webhookSecretKeyNavigationLabel)}
                path={getOrganizationWebhookSecretKeyPagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-users"
                label={intl.formatMessage(LeftNavigationBarMessages.teamNavigationLabel)}
                path={getOrganizationTeamPagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-credit-card"
                label={intl.formatMessage(LeftNavigationBarMessages.billingAndUsageNavigationLabel)}
                path={`/organizations/${organizationName}/billing`}
                variant="left-nav"
              />
            </li>
          </>
        )}

        <li>
          <MenuItem
            iconClassName="fa-solid fa-book"
            label={intl.formatMessage(LeftNavigationBarMessages.documentationNavigationLabel)}
            externalLink="https://docs.salad.com/introduction"
            variant="left-nav"
          />
        </li>
        <li>
          <MenuItem
            iconClassName="fa-solid fa-headset"
            label={intl.formatMessage(LeftNavigationBarMessages.contactSupportButtonLabel)}
            onClick={handleContactSupportClick}
            variant="left-nav"
          />
        </li>
      </ul>
      {isHelpScoutBeaconFailedToLoadModalOpen && (
        <HelpScoutBeaconFailedToLoadModal onClose={() => setIsHelpScoutBeaconFailedToLoadModalOpen(false)} />
      )}
    </>
  )
}
